//
//    Generated by trinidad-ui!
//
//
//    ██████╗  ██████╗     ███╗   ██╗ ██████╗ ████████╗    ███████╗██████╗ ██╗████████╗
//    ██╔══██╗██╔═══██╗    ████╗  ██║██╔═══██╗╚══██╔══╝    ██╔════╝██╔══██╗██║╚══██╔══╝
//    ██║  ██║██║   ██║    ██╔██╗ ██║██║   ██║   ██║       █████╗  ██║  ██║██║   ██║
//    ██║  ██║██║   ██║    ██║╚██╗██║██║   ██║   ██║       ██╔══╝  ██║  ██║██║   ██║
//    ██████╔╝╚██████╔╝    ██║ ╚████║╚██████╔╝   ██║       ███████╗██████╔╝██║   ██║
//    ╚═════╝  ╚═════╝     ╚═╝  ╚═══╝ ╚═════╝    ╚═╝       ╚══════╝╚═════╝ ╚═╝   ╚═╝
//
//
//    All changes in this file will be overwritten next time
//    someone adds or generates new assets.
//
//    Read more on https://git.svenskaspel.se/trinidad/trinidad-ui ...
//
//    @author Team Design-tech
//
//    ---

const path = '/images/ui/symbols/';
const cdn = global.window &&
  window.svs &&
  window.svs.core &&
  window.svs.core.config &&
  window.svs.core.config.data &&
  window.svs.core.config.data.svsconfig ?
window.svs.core.config.data.svsconfig.cdn :
'';

const svgs = {
  '18-logo-neg': '18-logo-neg-dbe3885c42070e6aafb0ad2a563dff0920c1f11b.svg',
  '18-logo-pos': '18-logo-pos-b6ff4c831529b66fd03513f9f1d93fdc348b6796.svg',
  'pix-mixen': 'pix-mixen-dd89b3db8c2a33dfb8795d46166124eef92fefb8.svg',
  'sok-header-inverted': 'sok-header-inverted-15a0b577c9d12580352bb6cb99172644d39c1273.svg',
  'sok-header': 'sok-header-0d5c3cd1f7a00c16766c027b3c0d9db86ee0cf73.svg',
  spelinspektionen: 'spelinspektionen-3c1568fedd322b957c7e08ce4a117e5507d9af34.svg',
  'stodlinjen-neg': 'stodlinjen-neg-b0a7494dcfc6bfe9107024d814ebd77b0f4b29b3.svg',
  stodlinjen: 'stodlinjen-718903f282abf80c481c7f75e1a41313926b7d1e.svg'
};
const getGuard = (name) => {
  let match = name
    .trim()
    .replace(/[ÅÄåä]/g, 'a')
    .replace(/[Öö]/g, 'o')
    .replace(/Éé/g, 'e')
    .replace(/ /g, '-')
    .replace(/[^A-Za-z0-9_-]/g, '')
    .toLowerCase();

  if (svgs[match]) {
    match = svgs[match];
  }

  return match;
};

const getSvg = (name) => {
  const svg = getGuard(name);
  /* eslint-disable no-console */
  return svg ? svg : false;
};

const getAssetUrl = (svg) => cdn + path + svg;

export default {
  getAssetUrl,
  getSvg
};
